<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Editar modalidad B"></header-title>
      <div v-if="convocatoria.id > 0">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <p class="subtitle-1 font-weight-bold">
                  {{ convocatoria.cite }}
                </p>
                <p>
                  La comisión de la ESFM/UA debe llenar el
                  <b>formulario de requisitos</b> de las siguientes modalidades
                  <b>B1, B2, B3, B4 y B5</b>.
                </p>
                <hr />
                <p class="mt-3">
                  <b>Postulante: {{ postulante.nombre }}</b> <br />
                  <b> Modalidad: {{ postulante.modalidad }} </b>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="12">
            <v-form ref="formr" @submit.prevent="confirmEnvio()">
              <v-card>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">
                            VERIFICACIÓN DE CUMPLIMIENTO DE REQUISITOS
                          </th>
                          <th class="text-center">CUMPLE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in datos.requisitos"
                          :key="index"
                        >
                          <td>
                            <b>{{ item.numeracion }}</b>
                          </td>
                          <td width="84%">
                            {{ item.descripcion }}
                          </td>
                          <td width="16%" class="text-center">
                            <v-radio-group v-model="item.cumple" row>
                              <v-radio
                                v-for="(oitem, jdx) in item.respuestas"
                                :key="jdx"
                                :label="oitem.label"
                                :value="oitem.value"
                                :rules="[item.cumple != null || '']"
                              ></v-radio>
                            </v-radio-group>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
              <!-- <v-card class="mt-3" v-if="datos.lengua">
                <v-card-text>
                  <v-simple-table >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left text-bold">
                            LENGUA ORIGINARIA
                          </th>
                          <th class="text-center">PUNTAJE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="2">
                            <v-switch
                              v-model="datos.aptiud"
                              :label="`¿La especialidad tiene nota de Lengua Originaria? ${
                                datos.aptiud ? 'Si' : 'No'
                              }`"
                              @change="resetValue"
                            ></v-switch>
                          </td>
                        </tr>
                       <tr v-if="datos.lengua">
                          <td width="85%">
                            Si la puntuación obtenida es mayor o igual a {{postulante.modalidad == "B1" ? "81" : "61"}},
                            la/el postulante es habilitado/a al cómputo de
                            promedios de Educación Secundaria Comunitaria o su
                            equivalente.
                          </td>
                          <td width="15%" class="text-center">
                            <v-text-field
                              class="my-1"
                              type="number"
                              v-model="datos.nota_lengua"
                              label=""
                              :rules="[(v) => !!v || 'El campo es requerido']"
                              hide-details
                              outlined
                            ></v-text-field>
                          </td>
                        </tr> -->
                        <!-- <tr v-if="datos.lengua">
                          <td colspan="2">
                            <v-text-field
                              class="mt-3"
                              label="Formulario de aptitud"
                              @click="pickFile"
                              v-model="documento_lengua"
                              append-icon="mdi-attachment"
                              :rules="[(v) => !!v || 'Seleccione el documento']"
                              filled
                              readonly
                            ></v-text-field>
                            <input
                              type="file"
                              style="display: none"
                              ref="archivo"
                              accept="application/pdf"
                              @change="onFilePicked"
                            />
                          </td>
                        </tr> 
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card> -->
              <v-card class="mt-3" v-if="datos.aptiud">
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left text-bold">
                            EVALUACIÓN LENGUA ORIGINARIA
                          </th>
                          <th class="text-center">PUNTAJE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr>
                          <td colspan="2">
                            <v-switch
                              inset
                              v-model="datos.aptiud"
                              :label="`¿La especialidad tiene Prueba de Aptitud? ${
                                datos.aptiud ? 'Si' : 'No'
                              }`"
                              @change="resetValue"
                            ></v-switch>
                          </td>
                        </tr> -->
                        <tr v-if="datos.aptiud">
                          <td width="85%">
                            Si la puntuación obtenida es mayor o igual a {{postulante.modalidad == "B1" ? "81" : "61"}},
                            la/el postulante es habilitado/a al cómputo de
                            promedios de Educación Secundaria Comunitaria o su
                            equivalente.
                          </td>
                          <td width="15%" class="text-center">
                            <v-text-field
                              class="my-1"
                              type="number"
                              v-model="datos.nota_aptitud"
                              label=""
                              :rules="[(v) => !!v || 'El campo es requerido']"
                              hide-details
                              outlined
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr v-if="datos.aptiud">
                          <td colspan="2" class="pt-2">
                            <b>
                              El formulario adjunto es el siguiente:
                              <a
                                elevation="2"
                                :href="`${serve}aptitud/${form_aptitud}`"
                                target="_blank"
                              >
                                Ver formulario adjunto
                              </a>
                              <v-switch
                                color="success"
                                v-model="file_change"
                                :label="`¿Quiere cambiarlo? ${
                                  file_change ? 'Si' : 'No'
                                }`"
                                @change="changeState()"
                              ></v-switch>
                            </b>
                          </td>
                        </tr>
                        <tr v-if="datos.aptiud && file_change">
                          <td colspan="2">
                            <v-text-field
                              class="mt-3"
                              label="Formulario de aptitud"
                              @click="pickFile"
                              v-model="documento"
                              append-icon="mdi-attachment"
                              :rules="[(v) => !!v || 'Seleccione el documento']"
                              filled
                              readonly
                            ></v-text-field>
                            <input
                              type="file"
                              style="display: none"
                              ref="archivo"
                              accept="application/pdf"
                              @change="onFilePicked"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
              <v-card class="mt-3" v-if="datos.notas">
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th colspan="4">
                            COMPUTO DE PROMEDIOS DE LOS CERTIFICADOS O LIBRETAS
                            DE EDUCACIÓN SECUNDARIA COMUNITARIA PRODUCTIVA
                          </th>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <span class="red--text"
                              >Tome en cuenta si la libreta está ponderado sobre
                              70 o 100 puntos.</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <th colspan="4" align="center">
                            <v-radio-group v-model="datos.tipo_promedio" row @change="promedioBase()">
                              <v-radio
                                label="¿ES SOBRE 70 PUNTOS?"
                                value="70"
                              ></v-radio>
                              <v-radio
                                label="¿ES SOBRE 100 PUNTOS?"
                                value="100"
                              ></v-radio>
                            </v-radio-group>
                          </th>
                        </tr>
                        <tr>
                          <th class="text-center text-bold">
                            PROMEDIO
                            {{ datos.tipo_promedio == "100" ? "1ro" : "7mo" }}
                            {{ datos.tipo_promedio == "100" ? "Secundaria" : "Primaria" }} 
                          </th>
                          <th class="text-center text-bold">
                            PROMEDIO
                            {{ datos.tipo_promedio == "100" ? "2do" : "8vo" }} 
                            {{ datos.tipo_promedio == "100" ? "Secundaria" : "Primaria" }} 
                          </th>
                          <th class="text-center text-bold">
                            Promedio
                            {{ datos.tipo_promedio == "100" ? "3ro" : "1ro" }}
                            Secundaria
                          </th>
                          <th class="text-center text-bold">
                            Promedio
                            {{ datos.tipo_promedio == "100" ? "4to" : "2do" }}
                            Secundaria
                          </th>
                          <th class="text-center text-bold">
                            Promedio
                            {{ datos.tipo_promedio == "100" ? "5to" : "3ro" }}
                            Secundaria
                          </th>
                          <th class="text-center text-bold">
                            Promedio
                            {{ datos.tipo_promedio == "100" ? "6to" : "4to" }}
                            Secundaria
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="!conSIE">
                          <!--  <td width="16%" v-for="(item, index) in postulante.informacion_academica" :key="index">
                            <v-text-field
                              class="text-center my-1"
                              type="number"
                              v-model="item.promedio" 
                              label=""
                              :rules="[(v) => !!v || 'El campo es requerido']"
                              hide-details
                              outlined
                              single-line
                              @change="promedioBase()"
                            ></v-text-field>
                          </td> 
                            <tr>-->
                          <td width="16%">
                            <v-text-field
                              class="text-center my-1"
                              type="number"
                              v-model="datos.promedios.nota1"
                              label=""
                              :rules="[(v) => !!v || 'El campo es requerido']"
                              hide-details
                              outlined
                              single-line
                              
                            ></v-text-field>
                          </td>
                          <td width="16%">
                            <v-text-field
                              class="text-center my-1"
                              type="number"
                              v-model="datos.promedios.nota2"
                              label=""
                              :rules="[(v) => !!v || 'El campo es requerido']"
                              hide-details
                              outlined
                            ></v-text-field>
                          </td>
                          <td width="16%">
                            <v-text-field
                              class="text-center my-1"
                              type="number"
                              v-model="datos.promedios.nota3"
                              label=""
                              :rules="[(v) => !!v || 'El campo es requerido']"
                              hide-details
                              outlined
                            ></v-text-field>
                          </td>
                          <td width="16%">
                            <v-text-field
                              class="text-center my-1"
                              type="number"
                              v-model="datos.promedios.nota4"
                              label=""
                              :rules="[(v) => !!v || 'El campo es requerido']"
                              hide-details
                              outlined
                            ></v-text-field>
                          </td>
                          <td width="16%">
                            <v-text-field
                              class="text-center my-1"
                              type="number"
                              v-model="datos.promedios.nota5"
                              label=""
                              :rules="[(v) => !!v || 'El campo es requerido']"
                              hide-details
                              outlined
                            ></v-text-field>
                          </td>
                          <td width="16%">
                            <v-text-field
                              class="text-center my-1"
                              type="number"
                              v-model="datos.promedios.nota6"
                              label=""
                              :rules="[(v) => !!v || 'El campo es requerido']"
                              hide-details
                              outlined
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan="4"
                            align="text-center font-weight-blacks titles"
                          >
                          <!-- <span v-if="datos.tipo_promedio == '70'">
                              PROMEDIO TOTAL (70):
                              <b class="blue--text">{{ promedioS }}</b>
                              <br />
                            </span>
                            <span
                              >PROMEDIO TOTAL (100):
                              <b class="blue--text">{{ promedioC }}</b>
                            </span> -->
                          </td>
                          <td colspan="2" align="text-center">
                            <v-select
                              class="mt-2"
                              label="Subsistema de educación"
                              v-model="datos.subsistema"
                              :items="['REGULAR', 'ALTERNATIVA', 'ESPECIAL']"
                              :rules="[(v) => !!v || 'Seleccione una opción']"
                              filled
                              readonly
                            ></v-select>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
              <v-card class="mt-3">
                <v-card-text>
                  <h3>¿El postulantes es ADMITIDO o NO ADMITIDO?</h3>
                  <p>
                    Tenga mucha precaución, siendo que define o no la admisión
                    del postulante.
                  </p>
                  <v-select
                    label="Resultado Final"
                    v-model="datos.estado_id"
                    :items="[
                      { id: 9, descripcion: 'ADMITIDO' },
                      { id: 10, descripcion: 'NO ADMITIDO' },
                    ]"
                    item-text="descripcion"
                    item-value="id"
                    :rules="[(v) => !!v || 'Seleccione una opción']"
                    filled
                  ></v-select>
                </v-card-text>
              </v-card>
              <v-card class="mt-3">
                <v-card-text>
                  <h3>COMISIÓN INSTITUCIONAL DE ESPECIALIDAD</h3>
                  <!--  <ol>
                    <li>
                      Un/a integrante del personal directivo/Coordinador o su
                      delegado (presidente/a)
                    </li>
                    <li>Un/a docente de especialidad (secretario/a)</li>
                    <li>Un/a docente de formación general (secretario/a)</li>
                  </ol> -->
                  <p>
                    Declaramos que los datos consignados en el presente
                    formulario son fidedignos y son acordes a lo estipulado en
                    la Convocatoria Pública y su Reglamento del Proceso de
                    Admisión de Postulantes a las ESFM/UA, en señal de
                    aceptación y conformidad firmamos:
                  </p>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>NOMBRE Y APELLIDOS</th>
                          <th>CARGO</th>
                          <th class="text-center">ACCIÓN</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in datos.comision"
                          :key="index"
                        >
                          <td>
                            {{ item.nombre }}
                          </td>
                          <td>
                            {{ item.cargo }}
                          </td>
                          <td align="center">
                            <v-btn
                              type="button"
                              icon
                              color="red"
                              @click="removeComision(item)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <td>
                          <v-text-field
                            class="mt-2 mr-1"
                            v-model.trim="comision.nombre"
                            label="Nombre y Apellidos"
                            @keypress="isText($event)"
                            hide-details
                            outlined
                            dense
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            class="mt-2 mr-1"
                            v-model.trim="comision.cargo"
                            label="Cargo"
                            @keypress="isText($event)"
                            hide-details
                            outlined
                            dense
                          ></v-text-field>
                        </td>
                        <td align="center">
                          <v-btn
                            type="button"
                            color="success"
                            @click="addComision"
                          >
                            Agregar
                          </v-btn>
                        </td>
                      </tfoot>
                    </template>
                  </v-simple-table>
                  <v-row class="mt-3">
                    <v-col class="text-center">
                      <v-btn type="submit" color="info" large>
                        Finalizar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <notify-card
          :title="notify_title"
          :load="notify_load"
          process="el proceso de admisión"
        ></notify-card>
      </div>
    </v-container>
    <v-dialog v-model="confirmDialog" persistent width="300">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text class="text-center">
          <div v-if="response_status == false">
            <p>¿Está seguro(a) de guardar la información editada?</p>
          </div>
          <div v-else>
            <p class="blue--text">El registro se modificó existosamente</p>
            <p>Imprima su Formulario Centralizador</p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="response_status == false"
            color="success"
            :loading="load_send"
            text
            @click="updateDatos"
            >Aceptar</v-btn
          >
          <v-btn
            v-else
            color="success"
            :loading="load_send"
            @click="printFormc"
          >
            Imprimir
          </v-btn>
          <v-btn
            v-if="response_status == false"
            color="red"
            text
            @click="confirmDialog = false"
            >Cancelar</v-btn
          >
          <v-btn v-else color="red" text @click="closeDialog">{{
            "Cerrar"
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle";
import NotifyCard from "@/components/NotifyCard";
import Service from "../utils/general";
import PDFService from "../utils/pdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "admision-editar-requisito",
  components: {
    HeaderTitle,
    NotifyCard,
  },
  data: () => ({
    notify_title: "Cargando",
    notify_load: true,
    load_search: false,
    load_send: false,
    type_state: 1,
    cedula_dni: "",
    convocatoria: {
      id: null,
      cite: "",
    },
    institucion_id: "",
    postulante: {
      iid: "",
      nombre: "",
      modalidad: "",
      cedula_dni:"",
      complemento:"",
      fecha_nacimiento:"",
      informacion_academica:[
        
      ],
    },
    modalidades: [],
    datos: {
      requisitos: [],
      aptiud: true,
      lengua: true,
      notas: false,
      nota_aptitud: "",
      nota_lengua: "",
      promedios: [],
      promedio_final: "",
      tipo_promedio: "100",
      subsistema: "",
      comision: [],
      estado_id: "",
      location: "aptitud",
    },
    promedioS :0,
    promedioC :0,
    documento: "",
    form_aptitud: "",
    archivo_aptitud: null,
    file_change: false,
    comision: {
      nombre: "",
      cargo: "",
    },
    response_status: false,
    confirmDialog: false,
    user: null,
    serve: "",
  }),
  created() {
    this.user = Service.getUser();
    if (this.user == null) {
      this.$router.replace({ name: "inicio" });
    }
  },
  computed: {
    postulanteData() {
      return this.$store.getters.getPostulante;
    },
    // promedioBase() {
    //   let sum =
    //     parseFloat(this.datos.promedios.nota1) +
    //     parseFloat(this.datos.promedios.nota2) +
    //     parseFloat(this.datos.promedios.nota3) +
    //     parseFloat(this.datos.promedios.nota4);
    //   let prom = 0;
    //   if (sum > 0) {
    //     prom = sum / 4;
    //     prom = this.datos.tipo_promedio == "70" ? prom : prom.toFixed(3);
    //   }
    //   return prom;
    // },
    // promedioFinal() {
    //   let prom = this.promedioBase;
    //   if (this.datos.tipo_promedio == "70") {
    //     prom = (prom / 70) * 100;
    //     prom = prom.toFixed(3);
    //   }
    //   return prom;
    // },
  },
  mounted() {
    const roles = [8, 11];
    if (this.user && roles.includes(this.user.rid)) {
      this.serve = Service.getServe();
      if (this.postulanteData.id == "") {
        this.$router.replace({ name: "admision-lista-postulante-b" });
      }
      this.postulante = this.postulanteData;
      this.getConvocatoria();
      if(this.postulante.modalidad == "B1" || this.postulante.modalidad == "B4"){
        this.datos.lengua = true;
        this.datos.notas = true;
       // if(this.postulante.modalidad != "B4") this.datos.notas = true;
      }else{
        this.datos.lengua = false;
           this.datos.aptiud= false;
            this.datos.notas = true;
      }
      
      this.institucion_id = this.user.ies;
      this.postulante.informacion_academica = []
      this.postulante.informacion_academica.push({institucioneducativa:"",gestion_tipo_id:"",grado:"",promedio: 0,des_dep: "",des_pro: "",des_sec_mun: "",distrito: "",zona: "",direccion: ""});
      this.postulante.informacion_academica.push({institucioneducativa:"",gestion_tipo_id:"",grado:"",promedio: 0,des_dep: "",des_pro: "",des_sec_mun: "",distrito: "",zona: "",direccion: ""});
      this.postulante.informacion_academica.push({institucioneducativa:"",gestion_tipo_id:"",grado:"",promedio: 0,des_dep: "",des_pro: "",des_sec_mun: "",distrito: "",zona: "",direccion: ""});
      this.postulante.informacion_academica.push({institucioneducativa:"",gestion_tipo_id:"",grado:"",promedio: 0,des_dep: "",des_pro: "",des_sec_mun: "",distrito: "",zona: "",direccion: ""});
      this.postulante.informacion_academica.push({institucioneducativa:"",gestion_tipo_id:"",grado:"",promedio: 0,des_dep: "",des_pro: "",des_sec_mun: "",distrito: "",zona: "",direccion: ""});
      this.postulante.informacion_academica.push({institucioneducativa:"",gestion_tipo_id:"",grado:"",promedio: 0,des_dep: "",des_pro: "",des_sec_mun: "",distrito: "",zona: "",direccion: ""});
    
      this.datos.promedios = {
        nota1: 0,
        nota2: 0,
        nota3: 0,
        nota4: 0,
        nota5: 0,
        nota6: 0,
      };
      
      
    } else {
      this.$router.replace({ name: "inicio" });
    }
    this.promedioBase();
  },
  methods: {
    ...mapActions(["toast"]),
    pickFile() {
      this.$refs.archivo.click();
    },
    promedioBase() {
      let sum =
      parseFloat(this.postulante.informacion_academica[0].promedio)+
        parseFloat(this.postulante.informacion_academica[1].promedio)+
        parseFloat(this.postulante.informacion_academica[2].promedio)+
        parseFloat(this.postulante.informacion_academica[3].promedio)+
        parseFloat(this.postulante.informacion_academica[4].promedio)+
        parseFloat(this.postulante.informacion_academica[5].promedio)
      let prom = 0;
      if (sum > 0) {
        prom = sum / 6;
        if (this.datos.tipo_promedio == "70") {
          this.promedioS = prom.toFixed(3);
          prom = (prom * 100) / 70;
          this.promedioC = prom.toFixed(3);
        }else{
          this.promedioC = prom.toFixed(3);
          prom = (prom * 70) / 100;
          this.promedioS = prom.toFixed(3);
        }
       // prom = this.datos.tipo_promedio == "70" ? prom : prom.toFixed(3);
      }
     // return prom;
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.documento = files[0].name;
        this.archivo_aptitud = files[0];
        /* if (files[0].size < 2057157) {
          this.documento = files[0].name;
          this.archivo_aptitud = files[0];
        } else {
          this.$refs.archivo.value = "";
          this.documento = "";
          this.archivo_aptitud = "";
          this.toast({
            color: "warning",
            tex: "El archivo no debe superar el peso permitido de <b>2MB</b>.",
          });
        } */
      } else {
        this.documento = "";
        this.archivo_aptitud = null;
      }
    },
    getConvocatoria() {
      this.notify_load = true;
      axios
        .get(Service.getUrlAPI() + "admision/modalidad", Service.getHeader())
        .then((response) => {
          this.convocatoria = response.data.convocatoria
            ? response.data.convocatoria
            : { id: null, cite: "" };
          this.notify_title = "Notificación";
          this.notify_load = false;
          this.getDatos();
        })
        .catch(() => {
          this.notify_load = false;
          console.error("Parametros no encontrado");
        });
    },

    getDatos() {
      if (this.postulante.modalidad != "") {
        axios
          .post(
            Service.getUrlAPI() + "admision/editar/notas",
            {
              convocatoria_id: this.convocatoria.id,
              institucion_id: this.institucion_id,
              postulante_id: this.postulante.id,
            },
            Service.getHeader()
          )
          .then((response) => {
            if (response.data.id > 0) {
              this.datos.requisitos = JSON.parse(response.data.requisitos);
              this.datos.estado_id = response.data.a_postulante.estado_id;
              this.datos.tipo_promedio = response.data.tipo_promedio;
              this.datos.promedios = JSON.parse(response.data.promedios);
              this.datos.subsistema = response.data.subsistema;
              this.datos.nota_lengua = response.data.nota_lengua;
              if (
                response.data.nota_aptitud &&
                response.data.nota_aptitud != ""
              ) {
                this.datos.aptiud = true;
                this.datos.nota_aptitud = response.data.nota_aptitud;
                this.form_aptitud = response.data.archivo;
              }
              this.datos.comision = JSON.parse(response.data.comision);
            }
          })
          .catch((error) => {
            console.error("Error al cargar registros", error);
          });
      }
    },

    resetValue() {
      //this.datos.nota_aptitud = "";
      this.documento = "";
      this.archivo_aptitud = "";
    },

    addComision() {
      if (this.comision.nombre != "" && this.comision.cargo != "") {
        this.datos.comision.push({
          nombre: this.comision.nombre,
          cargo: this.comision.cargo,
        });
        this.comision.nombre = "";
        this.comision.cargo = "";
      } else {
        this.toast({
          color: "info",
          text: "Ingrese el Nombre y Apellidos y Cargo para agregar",
        });
      }
    },

    removeComision(comision) {
      let index = this.datos.comision.findIndex((item) => item === comision);
      if (index != -1) {
        this.datos.comision.splice(index, 1);
      }
    },

    changeState() {
      this.documento = "";
      this.archivo_aptitud = null;
    },

    confirmEnvio() {
      this.datos.promedios.nota1 = parseFloat(this.postulante.informacion_academica[0].promedio).toFixed(3)
      this.datos.promedios.nota2 = parseFloat(this.postulante.informacion_academica[1].promedio).toFixed(3)
      this.datos.promedios.nota3 = parseFloat(this.postulante.informacion_academica[2].promedio).toFixed(3)
      this.datos.promedios.nota4 = parseFloat(this.postulante.informacion_academica[3].promedio).toFixed(3)
      this.datos.promedios.nota5 = parseFloat(this.postulante.informacion_academica[4].promedio).toFixed(3)
      this.datos.promedios.nota6 = parseFloat(this.postulante.informacion_academica[5].promedio).toFixed(3);
      
      
      if (this.$refs.formr.validate()) {
        if (this.datos.aptiud && 
            this.datos.nota_aptitud < 0 && 
            this.datos.nota_aptitud > 100) {
          this.toast({
            color: "info",
            text: "La nota de aptitud no puede ser mayor a 100",
          });
          return false;
        }
        const promedio_value = parseInt(this.datos.tipo_promedio);
        if (
          this.datos.promedios.nota1 > promedio_value ||
          this.datos.promedios.nota2 > promedio_value ||
          this.datos.promedios.nota3 > promedio_value ||
          this.datos.promedios.nota4 > promedio_value ||
          this.datos.promedios.nota5 > promedio_value ||
          this.datos.promedios.nota6 > promedio_value ||
          this.datos.promedios.nota1 < 0 ||
          this.datos.promedios.nota2 < 0 ||
          this.datos.promedios.nota3 < 0 ||
          this.datos.promedios.nota4 < 0 ||
          this.datos.promedios.nota5 < 0 ||
          this.datos.promedios.nota6 < 0
        ) {
          this.toast({
            color: "info",
            text: `Los PROMEDIOS no puede ser mayor a ${this.datos.tipo_promedio} o negativo`,
          });
          return false;
        }
        if (this.datos.comision.length > 0) {
          this.confirmDialog = true;
          this.response_status = false;
        } else {
          this.toast({
            color: "info",
            text: "Agregue a la comisión institucional",
          });
        }
      } else {
        this.toast({ color: "info", text: "Complete el formulario" });
      }
    },

    updateDatos() {
    //  let promedioS = this.promedioBase;
    //  let promedioC = this.promedioFinal;
      this.promedioBase();
      let ruta = "admision/editar/requisito";
      let datos = null;
      let myheader = Service.getHeader();
      if (this.file_change) {
        ruta = "admision/editar/requisitof";
        datos = new FormData();
        datos.append("convocatoria_id", this.convocatoria.id);
        datos.append("institucion_id", this.institucion_id);
        datos.append("postulante_id", this.postulante.id);
        datos.append("nota_aptitud", this.datos.nota_aptitud);
        datos.append("requisitos", JSON.stringify(this.datos.requisitos));
        datos.append("tipo_promedio", this.datos.tipo_promedio);
        datos.append("promedios", JSON.stringify(this.datos.promedios));
        datos.append(
          "promedio_final",
          JSON.stringify({
            setenta: this.promedioS,
            cien: this.promedioC,
          })
        );
        datos.append("subsistema", this.datos.subsistema);
        datos.append("comision", JSON.stringify(this.datos.comision));
        datos.append("estado_id", this.datos.estado_id);
        datos.append("location", this.datos.location);
        datos.append("archivo", this.archivo_aptitud);
        datos.append("nota_lengua", this.datos.nota_lengua);
        myheader = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: Service.getToken(),
          },
        };
      } else {
        datos = {
          convocatoria_id: this.convocatoria.id,
          institucion_id: this.institucion_id,
          postulante_id: this.postulante.id,
          nota_aptitud: this.datos.nota_aptitud,
          requisitos: JSON.stringify(this.datos.requisitos),
          tipo_promedio: this.datos.tipo_promedio,
          promedios: JSON.stringify(this.datos.promedios),
          promedio_final: JSON.stringify({
            setenta: this.promedioS,
            cien: this.promedioC,
          }),
          subsistema: this.datos.subsistema,
          comision: JSON.stringify(this.datos.comision),
          estado_id: this.datos.estado_id,
          nota_lengua:this.datos.nota_lengua
        };
      }
      this.load_send = true;
      axios
        .post(Service.getUrlAPI() + ruta, datos, myheader)
        .then((response) => {
          this.load_send = false;
          if (response.status == 200) {
            /* if (this.$refs.formr.validate()) this.$refs.formr.reset();
              this.tipo_promedio = "100",
              this.datos.comision = []; */
            this.toast({ color: "error", text: response.data.message });
          } else {
            this.response_status = true;
            this.toast({ color: "success", text: response.data.message });
          }
          // this.confirmDialog = false;
        })
        .catch(() => {
          this.load_send = false;
          this.toast({ color: "error", text: "No se pudo registrar" });
        });
    },

    printFormc() {
      this.load_send = true;
      axios
        .post(
          Service.getUrlAPI() + "admision/formulario/centralizador",
          {
            postulante_id: this.postulante.id,
            convocatoria_id: this.convocatoria.id,
            institucion_id: this.institucion_id,
          },
          Service.getHeader()
        )
        .then(async (response) => {
          this.load_send = false;
          var bodyPdf = await PDFService.bodyCentralizadorPDF(
            response.data,
            this.postulante.modalidad
          );
          var docDefinition = {
            pageSize: "LETTER",
            pageMargins: [38, 70, 38, 30],
            header: PDFService.setting().header,
            footer: PDFService.setting().footer,
            content: bodyPdf,
          };
          pdfMake
            .createPdf(docDefinition)
            .download("FormularioCentralizador.pdf");
        })
        .catch((err) => {
          this.load_send = false;
          console.error(err);
        });
    },

    closeDialog() {
      this.confirmDialog = false;
      this.$router.replace({ name: "admision-lista-postulante-b" });
    },

    isText: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode == 32 ||
        charCode == 209 ||
        charCode == 241 ||
        (65 <= charCode && charCode <= 90) ||
        (97 <= charCode && charCode <= 122)
      ) {
        return true;
      } else {
        evt.preventDefault();
        return false;
      }
    },
  },
};
</script>
